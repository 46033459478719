import youtubeVideo from "./youtubeVideo";
import { isVisibleFormModal } from "./utils";


const videoModal = document.querySelector("#video-modal");
let videoElement = null;
let player = null;
let oldURL = null;
const closeModal = () => {
    if (oldURL) {
        window.history.back();
    } else {
        location.href = "/";
    }
};

window.addEventListener("hashchange", (e) => {
    oldURL = e.oldURL;
    if (e.newURL.match("#video-modal")) {
        document.body.style.overflow = "hidden";
    } else if (e.oldURL.match("#video-modal") && !isVisibleFormModal()) {
        closeVideo();
        document.body.style.overflow = "auto";
    }
});

window.addEventListener("load", (e) => {
    if (location.href.match("#video-modal")) {
        document.body.style.overflow = "hidden";
    }
});

videoModal.addEventListener("click", (e) => {
    if (e.target.classList.contains("modal-wrapper")) {
        closeVideo();
        closeModal();
    }
});

videoModal.querySelector(".modal-close").addEventListener("click", () => {
    closeVideo();
    closeModal();
});

function handleOpenButtonClick() {
    openVideo();
}

function openVideo() {
    player.play();
}

function closeVideo() {
    player.pause();
}

function handleVideoReady() {
    const openButtonElement = document.getElementById("play_button");
    openButtonElement && openButtonElement.addEventListener("click", handleOpenButtonClick, false);
}

function handleVideoEnded() {
    closeVideo();
}

function init() {
    videoElement = document.querySelector(".video");

    const youtubePlayerElement = document.querySelector(".video__player");

    const props = {
        handleVideoReady,
        handleVideoEnded
    };

    player = new youtubeVideo(props);
    player.init(youtubePlayerElement.id);
}

init();