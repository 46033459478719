class YoutubeVideo
{
    constructor(props)
    {
        this._onPlayerReady = this._onPlayerReady.bind(this);

        this._props = props || {};
        this._player = null;
    }

    _onPlayerReady()
    {
        this._player.setPlaybackQuality("highres");

        if (this._props.handleVideoReady)
        {
            this._props.handleVideoReady();
        }
    }

    _onPlayerStateChange(event)
    {
        if (event.data === 0)
        {
            if (this._props.handleVideoEnded)
            {
                this._props.handleVideoEnded();
            }
        }
    }

    init(playerId)
    {
        const _this = this;

        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api?";

        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = function () {
            _this._player = new YT.Player(playerId, {
                videoId: playerId.replace("youtube-video-", ""),
                events: {
                    "onReady": _this._onPlayerReady,
                    "onStateChange": _this._onPlayerStateChange
                }
            });
        };
    }

    play()
    {
        if (this._player)
        {
            this._player.playVideo();
        }
    }

    pause()
    {
        if (this._player)
        {
            this._player.pauseVideo();
        }
    }

    stop()
    {
        if (this._player)
        {
            this._player.stopVideo();
        }
    }
}

export default YoutubeVideo;